import router from 'umi/router';
import { getData } from '@/services/request';

import { listenTokenRolling, listenUnauthorized } from '@/utils/request';
import * as storage from '@/services/storage';
import * as wsocket from '@/services/socketCluster';
import * as mqttSocket from '@/services/mqtt';
import * as loginService from '@openapi/login';
import { readProp } from '@/utils/tool';
import { getEnterpriseByAccountId, queryCompanyLogoApi } from '@/services/openapi/account';
import { GROUP_ROUTE_PATHS } from '../constant';
import { getUrlParams } from '@/utils/toolFunction';
const defaultInfo = {
  alarm: 0,
  disConnect: 0,
  malfunction: 0,
  total: 0,
  warn: 0
}
const { isHall } = getUrlParams()

const defaultStationDetail = {
  stationName: '',//站点名称
}
const initData = isHall ? {
  // toLocation: '/',
  token: undefined,
  // 账号ID
  accountId: "5zz19abdaf1fe700",
  // 当前客户ID(当前项目不需要读取这个字段)
  customerId: "5zw49985fe9b7120",
  // 当前站点ID
  // currentStationId: undefined,
  // 大有项目特例化参数
  // 能管站点ID
  saasStationId: "52119a0548313b60",
  stationDetail: defaultStationDetail,
  isMockStation: false,
  // 储能站点Id
  bessStationId: '611a996fc903f7a0', // 611f8c7b19a1cc21  61118c62d52bc860
  // 大有能管企业ID
  saasCustomerId: "5zw49985fe9b7120",
  // 大有储能服务商ID
  bessCustomerId: undefined,
  // 保供电策略中开关柜id
  switchgearId: '',
  loading: false,
  statics: {
    "secondSubstationId": "",
    "serviceProviderId": null,
    "building11NodeId": null,
    "building13To16NodeId": null,
    "airConditionNumOutDoor": null,
    "powerConsumptionOfAirCondition": null,
    "allStreetLampCircuitIdString": null,
    "officeAreaNodeId": null,
    "powerOfAirConditionNodeId": null,
    "heFengWeatherUserId": null,
    "all5GBaseStationCircuitIdString": null,
    "roomElectricityConsumptionTreeNodeId": null,
    "parkId": null,
    "allFanTreeNodeIdString": null,
    "teachingAreaNodeId": null,
    "saasAndStoredDTOList": null,
    "areaEnergyEfficiencyNodeId": null,
    "storedEnergyStationId": "",
    "publicAreaNodeId": null,
    "heFengWeatherKey": null,
    "firstSubstationId": "52219a0548b167e0",
    "building8To10NodeId": null,
    "studentsApartmentsNodeId": null,
    "thirdSubstationId": "",
    "waterLevelWarningRulesId": null,
    "summerIsComfortableScope": null,
    "fourthSubstationId": "",
    "allWaterPumpTreeNodeIdString": null,
    "powerSupplyConfigSwitchgearId": null,
    "totalEnergyDistributionNodeId": null,
    "building4To7NodeId": null,
    "building12NodeId": null,
    "dayouEnvironmentalMonitor": "52229a2d935f4460",
    "publicLightingTreeNodeId": null,
    "energyTypeId": null,
    "mainFireControlWaterPipeId": null,
    "winterAirConditioningComfortRange": null,
    "totalPowerSystemNodeId": null,
    "canteenNodeId": null,
    "sportCenterNodeId": null,
    "building1To3NodeId": null,
    "allBuildingTreeNodeIdString": null,
    "enterpriseId": "5zw49985fe9b7120",
    "saasStationId": "",
    "isMockStation": false,
    "lightingSocketLoadId": null,

  },
} : {
  // toLocation: '/',
  token: undefined,
  // 账号ID
  accountId: undefined,
  // 当前客户ID(当前项目不需要读取这个字段)
  customerId: undefined,
  // 当前站点ID
  // currentStationId: undefined,
  // 大有项目特例化参数
  // 能管站点ID
  saasStationId: '',
  isMockStation: false,
  // 储能站点Id
  bessStationId: '', // 611f8c7b19a1cc21  61118c62d52bc860
  storeStationId: '',
  // 大有能管企业ID
  saasCustomerId: undefined,
  // 大有储能服务商ID
  bessCustomerId: undefined,
  // 保供电策略中开关柜id
  switchgearId: '',
  loading: false,
  statics: {}
}

const initialState = () => ({ ...initData });

export default {
  state: initialState(),

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    loginFromSession (
      state,
      {
        payload: { token, customerId }
      }
    ) {
      return { ...state, token, customerId };
    },
    reset () {
      return initialState();
    }
  },

  effects: {
    *init (_, { put, select, take }) {
      // yield put({ type: 'environment/init' });
      // yield take('environment/init/@@end');

      yield put({ type: 'metricCode/fetch' });
      yield take('metricCode/fetch/@@end');
    },
    *checkLogin (_, { put, select, call }) {
      const { token, accountId: mayHasAccountId, customerId: mayHasCorrectCustomerId } = yield select(
        ({ app }) => app
      );

      let accountId = mayHasAccountId;
      let customerId = mayHasCorrectCustomerId;

      if (!token) {
        yield put({ type: 'logout' });
        return;
      }

      if (!accountId) {
        const tokenDataRes = yield call(loginService.getUserInfoByToken);
        const tokenDataOf = readProp(tokenDataRes);
        const tokenAccountId = tokenDataOf('data.accountId');
        if (!tokenAccountId) {
          yield put({ type: 'logout' });
          return;
        }
        accountId = tokenAccountId;
      }

      const userInfo = yield call(loginService.fetchEntireUserInfo, accountId, customerId);
      customerId = userInfo.customerId;
      if (!userInfo.accountId) {
        yield put({ type: 'logout' });
        return;
      }

      //

      /*  */
      const resetEnterpriseId = yield call(getEnterpriseByAccountId, { accountId });

      let enterpriseId = resetEnterpriseId.dataList?.[0]?.id;
      let enterpriseName = resetEnterpriseId.dataList?.[0]?.enterpriseName;
      const enterpriseProperty = resetEnterpriseId.dataList?.[0]?.enterpriseProperty;
      // 备份企业ID
      localStorage.setItem('enterpriseIdCache', enterpriseId);
      localStorage.setItem('enterpriseNameCache', enterpriseName);

      const entLogo = yield call(queryCompanyLogoApi, { customerId: enterpriseId });

      localStorage.setItem('enterpriseLog', entLogo?.data || '');
      // 从本地获取enterpriseId如果没有按默认的
      let enterpriseId_ = storage.get('customerId') || '';

      //集团总览页企业ID取登录用户的enterpriseId
      const urlParams = new URL(window.location.href);
      const hash = urlParams?.hash;
      //初始化
      if (enterpriseId_ == '') {
        storage.set('customerId', enterpriseId); //可切换企业ID
        storage.set('loginCustomerId', enterpriseId); //登录用户企业ID
      }

      if (enterpriseId_ !== '' && !(hash.endsWith('#/') || hash.endsWith('#/n-company/station-view'))) {
        enterpriseId = enterpriseId_;
        storage.set('customerId', enterpriseId);
      }

      const dayouResourceRes = yield call(getData, 'dayougroup', 'getStaticResourceByEnterpriseId', {
        enterpriseId
      });

      let data = {
        ownerId: dayouResourceRes.data?.storedEnergyStationId || null,
        feeMeasurementPointId: dayouResourceRes.data?.saasStationId || null
      };

      const { feeMeasurementPointId, ownerId } = localStorage.getItem('ext') && JSON.parse(localStorage.getItem('ext')) || {};

      if (!feeMeasurementPointId) localStorage.setItem('ext', JSON.stringify(data));

      const propOf = readProp(dayouResourceRes.data);

      if (dayouResourceRes.data?.saasStationId && !localStorage.getItem('stationIds')) {
        localStorage.setItem("stationIds", dayouResourceRes.data?.saasStationId) 
      }

      const isGroup = Object.values(GROUP_ROUTE_PATHS).some(path => {
        let finalPath = path;
        if (path.includes('/:stationId')) {
          finalPath = path.replace('/:stationId', '')
        }
        return window.location.hash.includes(`#${finalPath}`)
      }) || window.location.hash.includes('n-company/station-view')


      // 触发用户数据查询
      yield put({ type: 'user/injectUserInfo', payload: userInfo });
      // 获取权限信息
      const isEmptyPage = window.location.hash==='#/' ? true: false
      yield put({
        type: 'right/fetchAccess',
        payload: {
          accountId,
          customerId: propOf('enterpriseId'),
          // 1企业2集团
          type:  isEmptyPage ? enterpriseProperty:JSON.parse(localStorage.getItem("stationList")) ? 2 : (isGroup ? 2 : 1)
        }
      });

      try {
        dayouResourceRes.data.firstSubstationId = '52219a0548b167e0';
        dayouResourceRes.data.secondSubstationId = '';
        dayouResourceRes.data.thirdSubstationId = '';
        dayouResourceRes.data.fourthSubstationId = '';


      } catch (error) {

      }
      const stationId = feeMeasurementPointId || propOf('saasStationId')

      let stationDetail = defaultStationDetail

      if (stationId) {
        const stationInfoRes = yield call(getData, 'assetManagement', 'queryStation', {
          stationId
        });
        stationDetail = stationInfoRes.data || defaultStationDetail
      }

      yield put({
        type: 'update',
        payload: {
          accountId,
          customerId,
          isMockStation: stationId === '52129a3b1d9ee2e0' ? true : false,
          saasStationId: stationId,
          bessStationId: feeMeasurementPointId ? ownerId : propOf('storedEnergyStationId'), // 如果缓存有站点 Id，直接取对应的 ownerId 作为当前储能站点 Id
          saasCustomerId: propOf('enterpriseId'),
          bessCustomerId: propOf('serviceProviderId'),
          switchgearId: propOf('powerSupplyConfigSwitchgearId'),
          statics: dayouResourceRes?.data || {},
          storeStationId: propOf('storedEnergyStationId') || ownerId,
          enterpriseName,
          stationDetail,
          userInfo,
          provinceCode: resetEnterpriseId.dataList[0].provinceCode,
          cityCode: resetEnterpriseId.dataList[0].cityCode
        }
      });
    },

    *login (
      {
        payload: { data: user,callback /* , isAddingLog */ }
      },
      { put }
    ) {
      // if user.token === undefined
      storage.set('token', user.token);

      // 触发用户数据查询
      yield put({ type: 'user/injectUserInfo', payload: user });
      yield put({
        type: 'update',
        payload: { token: user.token, accountId: user.accountId, customerId: user.customerId }
      });
      callback && callback()
      // if (isAddingLog) {
      //   yield put({ type: 'bizlogUser/createItemLogin' });
      // }
    },
    *loading ({ payload: { callback, loading } = {} }, { put, select, call }) {
      yield put({ type: 'update', payload: { loading } });
    },
    *logout (_, { put, call }) {
      // 通知服务端 退登
      // yield call(services.logout);
      const res = yield call(getData, 'user', 'logout');
      // 清空 token
      storage.remove('token');
      // 清空 customerId
      storage.remove('customerId');

      storage.remove('loginCustomerId');

      localStorage.clear();
      sessionStorage.clear();

      // 清空 base 中相关信息
      yield put({ type: 'reset' });

      // 清空权限
      yield put({ type: 'right/clean' });

      yield call(wsocket.unregiterAll);
      yield call(router.replace, '/login');
    },
    *getAlarmInfoData ({ payload: { callback } = {} }, { put, select, call }) {
      const res = yield call(getData, 'dayougroup', 'queryTotalAlarm', {
        stationIds: [JSON.parse(localStorage.getItem('stationInfo')).stationId]
      });
      if (res.code === '200') {
        yield put({
          type: 'update',
          payload: {
            alarmInfo: res.data || defaultInfo
          }
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            alarmInfo: defaultInfo
          }
        });
      }
    },

    // *reLocate(_, { put, select }) {
    //   const toLocation = yield select(state => state.app.toLocation);
    //   router.push(toLocation);
    // },
  },

  subscriptions: {
    // 从storage中读取token的登录
    loadStorageToken ({ dispatch }) {
      const token = storage.get('token');
      const customerId = storage.get('customerId');
      if (token) dispatch({ type: 'loginFromSession', payload: { token, customerId } });
    },
    initTokenRolling () {
      // @/utils/request 中循环触发了心跳
      // 如果服务器返回了相应的响应头，则会带上 token 传到这里
      listenTokenRolling(token => {
        storage.set('token', token);
      });
    },

    initUnauthorized ({ dispatch }) {
      listenUnauthorized(() => {
        dispatch({ type: 'logout' });
      });
    },

    initWSDispatch ({ dispatch }) {
      return wsocket.watch((action, data) => {
        dispatch({ type: action, payload: data });
      });
    },

    initMqttDispatch ({ dispatch }) {
      return mqttSocket.watch((action, data) => {
        dispatch({ type: action, payload: data });
      });
    }
  }
};
