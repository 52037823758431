import { fetch } from 'dva';
import router from 'umi/router';

let unauthorizedListener = null;
let onTokenRolling = null;

async function parseRes(response) {
  const contentType = response.headers.get('Content-type');

  if (contentType.startsWith('application/json')) {
    const data = await response.json();
    return data;
  }
  if (contentType.startsWith('text/html')) {
    const data = await response.text();
    return data;
  }
  throw new Error('unknown content type');
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    if (unauthorizedListener) unauthorizedListener();
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function rollingToken(response) {
  const token = response.headers.get('token-rolling');
  if (token && token.length > 0) onTokenRolling(token);
  return response;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(rollingToken)
    .then(parseRes)
    .then(data =>{
      if(data?.error==="unauthorized"){
        router.push('/login')
      }
     return data
    })
    .catch(err => ({ err }));
}

export async function listenUnauthorized(fn) {
  unauthorizedListener = fn;
}
export async function listenTokenRolling(fn) {
  onTokenRolling = fn;
}
